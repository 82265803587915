.home-container {
  height: 100%;
  padding-bottom: 40px;

  .logo-container {
    height: 80px;
    padding: 6px;

    .home-logo {
      height: 100%;
      padding-left: 10px;
    }
  }

  .home-background {
    width: 100%;
    height: calc(100vh - 80px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../assets/home-updated.jpeg");
    display: flex;
    justify-content: unset;
    // align-items: center;

    @media screen and (max-width: 1024px) {
      background-image: url("../../assets/home-updated.jpeg");
      align-items: unset;
      justify-content: center;
      background-position: 33%;
    }

    @media screen and (max-width: 768px) {
      align-items: unset;
      justify-content: center;
      background-position: 66% 100%;
      background-size: 1500px;
    }

    .tagline {
      display: flex;
      flex-direction: column;
      align-items: center;

      color: #747476;
      margin: unset;
      font-family: "Gloock", serif;
      // font-size: 40px;
      // padding-left: 20%;
      font-size: 56px;
      padding-left: 40%;
      padding-top: 10%;

      @media screen and (max-width: 768px) {
        padding-top: 50%;
        display: flex;
        flex-direction: column;
        padding-left: unset;
        // font-size: 20px;
        font-size: 36px;
      }

      @media screen and (max-width: 1024px) {
        padding-top: 33%;
        display: flex;
        flex-direction: column;
        padding-left: unset;
      }

      .tagline-primary {
        margin-bottom: 10px;
      }

      .tagline-secondary {
        // font-size: 88px;
        font-size: 32px;
        font-family: "Urbanist", sans-serif;
        font-weight: 700;

        @media screen and (max-width: 768px) {
          // font-size: 44px;
          font-size: 20px;
        }
      }
    }
  }
}
