.navigation {
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #f1f2f4;
  color: black;

  @media screen and (max-width: 768px) {
    background-color: #e8e9eb;
  }

  .brand-name {
    height: 100%;

    .home-logo {
      height: 100%;
      padding-left: 10px;
    }
  }

  .hamburger {
    // removes default border on button element
    border: 0;
    height: 35px;
    width: 35px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #4a4d4e;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    // positions the icon to the right and center aligns it vertically
    position: absolute;
    top: 60%;
    right: 25px;
    transform: translateY(-50%);
    display: none;

    &:hover {
      background-color: #4a4d4e;
    }

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  .navigation-menu {
    margin-left: auto;

    ul {
      display: flex;
      padding: 0;
    }

    li {
      list-style-type: none;
      margin: 0 1rem;

      a {
        text-decoration: none;
        display: block;
        width: 100%;
        color: #000;

        &:hover {
          color: #747476;
        }

        @media screen and (max-width: 768px) {
          color: #fff;
        }
      }

      .contact-menu {
        color: #4a4d4e;
        font-weight: 700;

        @media screen and (max-width: 768px) {
          color: #4a4d4e;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      // navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)
      position: absolute;
      top: 50px;
      right: 0;
      // stacks the li tags vertically
      flex-direction: column;
      // makes menu span full height and width
      width: 100%;
      height: calc(100vh - 77px);
      background-color: white;
      padding-top: 4px;
    }
    .navigation-menu li {
      // centers link text and strips off margin
      text-align: start;
      margin: 0;
      padding: 0px 10px;
    }
    .navigation-menu li a {
      color: #4a4d4e;
      // increases the surface area of the anchor tag to span the full width of the menu
      width: 100%;
      padding: 0.25rem 0;
    }
    .navigation-menu li:hover {
      // background-color: #eee;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* previous styles */
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
      height: fit-content;
      width: fit-content;
      background-color: transparent;
    }
  }
}
