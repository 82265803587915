.gallery-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-bottom: 80px;

  .heading {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 60px;
    font-family: "Gloock", serif;

    @media screen and (max-width: 1024px) {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  .gallery-section {
    .section-one {
      display: flex;
      height: 450px;
      justify-content: center;
      @media screen and (max-width: 1024px) {
        height: 300px;
      }

      @media screen and (max-width: 600px) {
        height: 150px;
      }

      .section-one-a {
        height: 430px;

        .section-one-item {
          height: 430px;

          @media screen and (max-width: 1024px) {
            height: 280px;
          }

          @media screen and (max-width: 600px) {
            height: 140px;
          }
        }

        @media screen and (max-width: 1024px) {
          height: 300px;
        }

        @media screen and (max-width: 600px) {
          height: 150px;
        }
      }

      .section-one-item {
        padding: 10px;

        @media screen and (max-width: 600px) {
          padding: 5px;
        }
      }
    }

    .section-two {
      display: flex;
      height: 460px;
      justify-content: center;

      @media screen and (max-width: 1024px) {
        height: 310px;
      }

      @media screen and (max-width: 600px) {
        height: 150px;
      }

      .section-one-item {
        padding: 10px;

        @media screen and (max-width: 600px) {
          padding: 5px;
        }
      }
    }
  }
}
