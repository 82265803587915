.footer-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #4a4d4e;
  color: #fff;

  .footer-content {
    margin-bottom: 15px;

    @media screen and (max-width: 1024px) {
      text-align: center;
      font-size: 12px;
    }
  }
}
