.clients-container {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-bottom: 130px;

  @media screen and (max-width: 1024px) {
    padding-bottom: 70px;
  }

  .heading {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 60px;
    font-family: "Gloock", serif;

    @media screen and (max-width: 1024px) {
      font-size: 28px;
      margin-bottom: 12px;
    }

    @media screen and (max-width: 768px) {
      margin-bottom: 12px;
    }
  }

  .clients-logos {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    @media screen and (max-width: 1024px) {
      justify-content: center;
    }

    .logo-item {
      width: 150px;
      margin: 30px;

      @media screen and (max-width: 1024px) {
        width: 90px;
        margin: 24px;
      }

      @media screen and (max-width: 768px) {
        width: 75px;
        margin: 20px;
      }
    }

    .logo-flipkart {
      width: 220px;

      @media screen and (max-width: 1024px) {
        width: 132px;
      }

      @media screen and (max-width: 768px) {
        width: 110px;
      }
    }

    .logo-delhivery {
      width: 200px;

      @media screen and (max-width: 1024px) {
        width: 120px;
      }

      @media screen and (max-width: 768px) {
        width: 80px;
      }
    }

    .logo-jeeves {
      width: 220px;

      @media screen and (max-width: 1024px) {
        width: 132px;
      }

      @media screen and (max-width: 768px) {
        width: 110px;
      }
    }

    .logo-mcd {
      width: 125px;

      @media screen and (max-width: 1024px) {
        width: 75px;
      }

      @media screen and (max-width: 768px) {
        width: 62px;
        padding-left: 10px;
      }
    }

    .logo-scoopso {
      background-color: #330768;
    }

    .logo-inked-corridors {
      @media screen and (max-width: 768px) {
        padding-left: 25px;
      }
    }

    .logo-sudharma {
      width: 200px;

      @media screen and (max-width: 1024px) {
        width: 120px;
      }

      @media screen and (max-width: 768px) {
        width: 100px;
      }
    }
  }
}