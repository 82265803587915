.services-container {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-bottom: 80px;

  .heading {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 20px;
    font-family: "Gloock", serif;

    @media screen and (max-width: 1024px) {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  .services-items-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
