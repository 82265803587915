.about-us-container {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  .heading {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 20px;
    font-family: 'Gloock', serif;

    @media screen and (max-width: 1024px) {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  .content-section {
    padding: 24px;

    .content {
      font-size: 21px;
      text-align: center;
      line-height: 1.75;
      max-width: 990px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      font-family: "Urbanist", sans-serif;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }
}
