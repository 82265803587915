.services-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  &:hover {
    background-color: #4a4d4e;
    border-radius: 10%;

    .services-label {
      color: white;
    }

    .services-logo {
      filter: invert(1);
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 30px;
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
  }

  .services-logo {
    height: 150px;

    @media screen and (max-width: 1024px) {
      height: 100px;
    }

    @media screen and (max-width: 768px) {
      height: 50px;
    }
  }

  .services-label {
    font-weight: 700;
    font-size: 21px;
    cursor: default;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
}
