.contact-us-container {
  // max-width: 1220px;
  // margin-left: auto;
  // margin-right: auto;
  width: 100%;
  padding-bottom: 15px;
  background: #4a4d4e;
  color: #fff;

  .heading {
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 60px;
    font-family: "Gloock", serif;

    @media screen and (max-width: 1024px) {
      font-size: 28px;
      margin-bottom: 24px;
    }
  }

  .contact-us-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contact-us-item {
      display: flex;
      align-items: center;
      font-size: 18px;
      margin-bottom: 16px;

      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }

      .contact-icons {
        height: 24px;
        margin-right: 24px;
        filter: invert(1);

        @media screen and (max-width: 1024px) {
          margin-right: 12px;
        }
      }
    }
  }

  .map-container {
    padding-top: 10px;
  }
}
